import "./checkbox.scss";
import { ReactComponent as MarkImg } from "../../assets/images/UI/mark.svg";

function Checkbox(props) {
    const checkboxClicked = props.value;
    const setCheckboxState = props.setValue;

    return (
        <div className="checkbox__wrapper" onClick={() => setCheckboxState(!checkboxClicked)}>
            <input className="checkbox" type="checkbox" {...props.inputAttributes}/>
            <div className={ "checkmark " + (checkboxClicked ? "checkmark_checked" : "") } >
                {checkboxClicked && <MarkImg />}
            </div>
        </div>
    )
}

export default Checkbox;