import "./filters.scss";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useState } from "react";

function Filters(props) {

    const [currentSearch, setCurrentSearch] = useState("");


    return (
        <div className="main-page__filters__wrapper">
            <div className="main-page__filters">
                <p>Sort by: </p>
                <div className="main-page__filters__sort">
                    <button 
                        className={props.sort === 'rand' ? 'main-page__filters_selected' : ''}
                        onClick={() => props.setSort('rand')}
                    >
                        Random
                    </button>
                    <button 
                        className={props.sort === 'asc' ? 'main-page__filters_selected' : ''}
                        onClick={() => props.setSort('asc')}
                    >
                        Punk ID (ascending)
                    </button>
                    <button 
                        className={props.sort === 'des' ? 'main-page__filters_selected' : ''}
                        onClick={() => props.setSort('des')}
                    >
                        Punk ID (descending)
                    </button>
                </div>
            </div> 
            <div className="main-page__filters">
                <div className="filters__checkbox">
                    <Checkbox 
                        value={props.idsShown} 
                        setValue={props.setIdState} 
                        inputAttributes={{
                            id: "alwaysShow"
                        }}
                    />
                    <label htmlFor="alwaysShow">Show details</label>
                </div>
                <input type="text" placeholder="Find Punk ID" value={currentSearch} onInput={e => setCurrentSearch(e.target.value)}/>
                <button onClick={() => props.setSearch(currentSearch)}>Search</button>
            </div>
        </div>
        
    )
}

export default Filters;