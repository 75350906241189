import "./introduction.scss";
import { ReactComponent as TwitterIcon } from "../../../../assets/images/UI/twitter-icon.svg";
import punksGif from "../../../../assets/images/UI/punks.gif";
function Introduction() {
  return (
    <div className="main-page__introduction">
      <div className="introduction__title__wrapper">
        <div className="introduction__logo__wrapper">
          <div className="introduction__logo">
            <img src={punksGif} alt="Item img" />
          </div>
          <div className="introduction__logo__description">
            <h3>1019 / 1019 minted!</h3>
          </div>
        </div>

        <div className="introduction__title__text">
          <h1>
            Monero Punks
            <a
              href="https://twitter.com/MoneroPunks"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </h1>
          <h2>The first 1k NFT collection on Monero</h2>
        </div>
      </div>
      <div className="introduction__description">
        <p>
          Pick a punk that hasn’t been minted yet, and mint it for yourself
          following the guide available{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://mordinals.gitbook.io/handbook/how-to-mint"
          >
            here
          </a>
          . Each image hash is unique and could only be minted once.
        </p>
      </div>
    </div>
  );
}

export default Introduction;
