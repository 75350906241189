import './App.scss';
import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './pages/Main/Main';

function App() {
    return (
        <div className="App">
            <Router>
                <Suspense fallback={<></>}>
                    <Routes>
                        <Route exact path='/' element={<Main />}></Route>
                    </Routes>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
