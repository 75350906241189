import "./main.scss";
import Introduction from "./components/Introduction/Introduction";
import Filters from "./components/Filters/Filters";
import ItemsGrid from "./components/ItemsGrid/ItemsGrid";
import { useState } from "react";

function Main() {
    const [ sortType, setSortType ] = useState('rand');
    const [ idsShown, setIdState ] = useState(false);
    const [ search, setSearch ] = useState('');

    return (
        <div className="main-page">
            <Introduction />
            <Filters idsShown={idsShown} setIdState={setIdState} setSearch={setSearch} sort={sortType} setSort={setSortType}/>
            <ItemsGrid idsShown={idsShown} setIdState={setIdState} search={search} sortType={sortType}/>
        </div>
    )
}

export default Main;