import "./itemsGrid.scss";
import React, { useState, useEffect } from "react";
import itemsData from "../../../../data/items.json";

function ItemsGrid(props) {

    const [ allItems, setAllItems ]= useState([]);
    const [ totalItems, setTotalItems ]= useState(0);
    const offset = 40;

    async function updateItems(from = 0, to, updateOnly = false, search = '') {

        from = search ? 0 : parseInt(from, 10);
        to = parseInt(to, 10) || Infinity;
        
        const itemsDataCopy = JSON.parse(JSON.stringify(itemsData));

        let result = props.sortType === 'rand' ? itemsDataCopy : itemsDataCopy.sort((a, b) => {
            if (props.sortType === 'asc') {
                return a.new_stem - b.new_stem;
            } else if (props.sortType === 'des') {
                return b.new_stem - a.new_stem;
            }
        });
        
        if (search) {
            result = result.filter(e => e.new_stem === search);
        }

        result = result.slice(from, to);


        if (updateOnly) {
            setAllItems(result);
        } else {
            setAllItems([...allItems, ...result]);
        }
    }

    useEffect(() => {
        async function updateContent() {
            setTotalItems(1019);
            updateItems(0, offset, true, props.search);
        }

        updateContent();
    }, [props.search, props.sortType]);

    const idsShown = props.idsShown;

    
    function Item(props) {
        const [ descriptionVisible, setDescriptionState ] = useState(false);
        const [ imgData, setImgData ] = useState('');

        useEffect(() => {
            import(`../../../../data/images/${props.id}.png`)
            .then(r => setImgData(r.default));
        }, [props.id]);

        return (
            <div onMouseOver={() => setDescriptionState(true)} onMouseOut={() => setDescriptionState(false)}>
                { (idsShown || descriptionVisible) && <div className="item__description">
                    <p>#{props.id}</p>
                </div> }

                {imgData &&
                    <img 
                        src={imgData} 
                        draggable={false} 
                        alt={`#${props.id}`} 
                    />
                }
            

                {(idsShown || descriptionVisible) && 
                    <div className="item__description item__description_bottom">
                        <a href={`https://mordinals.org/item/${props.ordinalID}`} target="_blank" rel="noreferrer">mordinal #{props.ordinalID}</a>
                    </div> 
                }
            </div>
        )
    }

    return (
        <div className="main-page__items-wrapper">
            <div className="main-page__items-grid">
                {allItems.map((e) => 
                    <Item key={e.new_stem} id={e.new_stem} ordinalID={e.ordinal_id}/>
                )}
            </div>
            {totalItems !== allItems.length && !props.search && 
                <button onClick={() => updateItems(allItems.length, allItems.length + offset)}>Load more</button>
            }
        </div>
    )
}

export default ItemsGrid;